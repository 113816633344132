module.exports = [{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"showCaptions":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-78443851-3","head":false,"anonymize":true,"respectDNT":true,"cookieDomain":"bahai.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
